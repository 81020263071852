import { LoginType } from './application';
import type { FleAppEnv, BaseURLMap } from '@/types/const/serviceEnv';

export const fleAppEnv: FleAppEnv = fleProcess.fleEnv || 'prod';

export const baseURLMap: BaseURLMap = {
  java: {
    dev: 'https://dev-gateway.fxqifu.net/pangu',
    qa: 'https://qa-gateway.fxqifu.net/pangu',
    prod: 'https://gateway.fxqifu.net/pangu',
  },
};

export type EnvURLMap = {
  [E in FleAppEnv]: string;
};
type ApplicationUrlMapType = {
  [K in LoginType]: EnvURLMap;
};
export const ApplicationUrlMap: ApplicationUrlMapType = {
  // 会员自有供应商端
  '40': {
    dev: 'https://dev-supplier.wowcarp.com',
    qa: 'https://qa-supplier.icarp.vip',
    prod: 'https://supplier.icarp.vip',
  },
  // 智鲤
  '90': {
    dev: 'https://dev.icarp.vip',
    qa: 'https://qa.icarp.vip',
    prod: 'https://console.icarp.vip',
  },
  // 万鲤商联（废弃）
  // '100': {
  //   dev: 'https://dev.carpunion.com',
  //   qa: 'https://qa.carpunion.com',
  //   prod: 'https://carpunion.com',
  // },
  // 万鲤商联（原妙鲤）
  '120': {
    dev: 'https://dev.carpunion.com',
    qa: 'https://qa.carpunion.com',
    prod: 'https://carpunion.com',
  },
  // 百鲤跃川客服平台
  '140': {
    dev: 'https://dev-csc.carpdance.com/service',
    qa: 'https://qa-csc.carpdance.com/service',
    prod: 'https://csc.carpdance.com/service',
  },
  // 妙鲤运营后台
  '150': {
    dev: 'https://dev-admin.wowcarp.com',
    qa: 'https://qa-admin.wowcarp.com',
    prod: 'https://admin.wowcarp.com',
  },
  // 智鲤运营后台
  '160': {
    dev: 'https://dev-admin.icarp.vip',
    qa: 'https://qa-admin.icarp.vip',
    prod: 'https://admin.icarp.vip',
  },
  // 璇玑
  '170': {
    dev: 'https://dev.fle-ai.com',
    qa: 'https://qa.fle-ai.com',
    prod: 'https://www.fle-ai.com',
  },
  // 分销商城通用
  '180': {
    dev: 'https://dev-mall.icarp.vip',
    qa: 'https://qa-mall.icarp.vip',
    prod: 'https://admin-mall.icarp.vip',
  },
  // 分销商城定制（妙货集采商城）
  '190': {
    dev: 'https://dev-mall.carpunion.com',
    qa: 'https://qa-mall.carpunion.com',
    prod: 'https://admin-mall.carpunion.com',
  },
  '200': {
    dev: 'https://dev-dist.icarp.vip',
    qa: 'https://qa-dist.icarp.vip',
    prod: 'https://dist.icarp.vip',
  },
  '210': {
    dev: '-',
    qa: 'https://qa-dx.carpunion.com',
    prod: 'https://dx.carpunion.com',
  },
  '220': {
    dev: '-',
    qa: 'https://qa.tmalljx.com',
    prod: 'https://tmalljx.com',
  },
};

type codeType = number;
export const DataCode: Map<string, codeType> = new Map([
  ['SUCCESS', 2000],
  ['LOGIN_ERROR', 100003],
  ['LOGIN_WARNING', 60000302],
]);

let { host } = window.fleLocation;
if (host.indexOf('localhost') > -1 || host.indexOf('192.168') > -1) {
  host = ApplicationUrlMap['220'][fleAppEnv].replace(/^https?:\/\//i, '');
}

export const loginLink = `${host}/account/login`;

export const ROUTER_BASE = 'account';
export const BaseServiceType = 'java';
export const TokenKey = 'token';
export const BaseURL: string = baseURLMap[BaseServiceType][fleAppEnv] || '';
export const getApplicationUrl = (loginType: LoginType) => {
  return `${ApplicationUrlMap[loginType][fleAppEnv]}`;
};

export const carpunionReqsterAward = true;
